<template>
  <div class="menu-container">
    <SlDialog
      :title="title"
      :visible.sync="show"
      :width="dialogWidth"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :lock-scroll="false"
      append-to-body
      :before-close="close"
      @cancel="close"
      @closed="closed"
    >
      <SlListView
        @gotoPage="gotoPage"
        :total="page.total"
        :pageIndex="page.pageIndex"
        :pageSize="page.pageSize"
      >
        <div solt="search">
          <SlSearchForm
            ref="SlSearchForm"
            v-model="query"
            :items="searchItems"
            :loading="tableLoading"
            :lg="12"
            :xl="8"
            @reset="gotoPage(page.pageSize)"
            @search="gotoPage(page.pageSize)"
          ></SlSearchForm>

          <SlTableInfo
            :tableData="tableData"
            :columns="columns"
            :multiple="true"
            max-height="360"
            @selection-change="handleSelectionChange"
            headerAlign="left"
          ></SlTableInfo>
        </div>
      </SlListView>

      <template #bottom>
        <el-button @click="close">{{$t('button.cancelText')}}</el-button>
        <el-button type="primary" @click="handleSubmit">{{$t('button.enterText')}}</el-button>
      </template>
    </SlDialog>
  </div>
</template>

<script>
import OemInventory from '@api/oemInventory'

export default {
  name: 'BaseBoardSku',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      title: '选择底版SKU',
      dialogWidth: '50%',
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      query: {},
      searchItems: [
        {
          type: 'input',
          label: '底版SKU',
          name: 'skuCode'
        },
        {
          type: 'input',
          label: '商品名称',
          name: 'productName'
        }
      ],
      tableLoading: false,
      tableData: [],
      multipleSelection: [],
      columns: [
        {
          name: 'skuCode',
          label: '底版SKU'
        }, {
          name: 'skuImage',
          label: '图片',
          isImage: true,
          data: {
            imgSize: '6rem'
          }
        }, {
          name: 'productName',
          label: '商品名称'
        }
      ]
    }
  },
  methods: {
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    handleSubmit () {
      if (this.multipleSelection.length) {
        this.$emit('submited', this.multipleSelection)
        this.close()
      } else {
        this.$message.warning('请选择SKU！')
      }
    },
    gotoPage (pageSize = 10, pageIndex = 1) {
      const params = this.generateParams(pageSize, pageIndex)
      this.tableLoading = true
      OemInventory.inventoryPage(params).then(res => {
        if (res.success) {
          const data = res.data || {}
          const list = data.list || []
          // extra key: wastageQuantity(用于损耗)
          list.forEach(item => {
            item['wastageQuantity'] = ''
          })
          this.tableData = list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    generateParams (pageSize, pageIndex) {
      return {
        ...this.query,
        pageIndex,
        pageSize
      }
    },
    close () {
      this.$emit('update:show', false)
    },
    closed () {
      this.$refs.SlSearchForm.resetFormData() // 重置查询条件
      // 重置 table
      this.gotoPage()
    }
  }
}
</script>

<style scoped lang="scss">
.operation-btn {
  margin-left: 0 !important;
  margin-right: 0.8rem;
}
</style>
