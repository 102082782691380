<template>
  <SlDialog
    :title="title"
    :visible.sync="show"
    :width="dialogWidth"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :lock-scroll="false"
    :append-to-body="true"
    :before-close="close"
    @cancel="close"
    @closed="closed"
  >
    <el-form ref="form" :model="tableForm">
      <div class="mb-2rem">
        <SlButton type="primary" boxShadow="primary" @click="onOpenSkuDialog">添加底版SKU</SlButton>
      </div>

      <SlTableInfo
        :isEmbedTable="true"
        :tableData="tableForm.wastageBatch"
        :columns="columns"
        headerAlign="left"
        max-height="360"
      >
        <template #wastageQuantity="{ row, index }">
          <el-form-item
            label-width="0px"
            :prop="'wastageBatch.' + index + '.wastageQuantity'"
            :key="row.skuCode"
            :rules="rules.wastageQuantity"
            style="margin-top: 18px;"
          >
            <el-tooltip
              v-if="row.available <= 0"
              content="库存可用数量不足，无法进行报损"
              placement="top"
              effect="light"
            >
              <el-input
                :disabled="row.available <= 0"
                v-model="row.wastageQuantity"
                v-slFormatNumber="numberFormat(row.available)"
                placeholder=">0"
              ></el-input>
            </el-tooltip>
            <el-input
              v-else
              v-model="row.wastageQuantity"
              v-slFormatNumber="numberFormat(row.available)"
              placeholder=">0"
            ></el-input>
          </el-form-item>
        </template>
        <template #operation="{ row, index }">
          <el-button type="text" @click="onRemove(index)">移除</el-button>
        </template>
      </SlTableInfo>

      <el-form-item
        label="损耗原因："
        label-width="110px"
        prop="wastageReason"
        :rules="rules.wastageReason"
        style="margin-top: 16px;"
      >
        <el-input
          type="textarea"
          v-model="tableForm.wastageReason"
          rows="3"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <template #bottom>
      <el-button @click="close">{{$t('button.cancelText')}}</el-button>
      <el-button
        type="primary"
        :loading="handleLoading"
        @click="handleSubmit"
      >{{$t('button.enterText')}}</el-button>
    </template>
    <!-- Dialog -->
    <BaseBoardSku :show.sync="innerVisible" @submited="getBaseBoardSkus" />
  </SlDialog>
</template>
<script>
import OemInventory from '@api/oemInventory'
import BaseBoardSku from './BaseBoardSku.vue'
import { uniqBy } from 'lodash'

export default {
  name: 'ApplyDialog',
  components: { BaseBoardSku },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    numberFormat () {
      return max => {
        return { type: 'integer', max, compareLength: false, includeZero: false }
      }
    }
  },
  data () {
    return {
      title: '新增底版报损',
      handleLoading: false,
      dialogWidth: '60%',
      columns: [
        {
          name: 'skuCode',
          label: '底版SKU'
        }, {
          name: 'skuImage',
          label: '图片',
          isImage: true,
          data: {
            imgSize: '6rem'
          }
        }, {
          name: 'productName',
          label: '商品名称'
        }, {
          name: 'available',
          label: '可用库存'
        }, {
          name: 'wastageQuantity',
          label: '损耗数量'
        }, {
          name: 'operation',
          label: '操作'
        }
      ],
      tableForm: {
        wastageBatch: [],
        wastageReason: ''
      },
      rules: {
        wastageQuantity: [
          {
            required: true,
            message: '请输入损耗数量',
            triggger: 'blur'
          }
        ],
        wastageReason: [
          {
            required: true,
            message: '请输入损耗原因'
          }
        ]
      },
      // inner dialog
      innerVisible: false
    }
  },
  methods: {
    onOpenSkuDialog () {
      this.innerVisible = true
    },
    getBaseBoardSkus (wastageBatch) {
      const _wastageBatch = JSON.parse(JSON.stringify(this.tableForm.wastageBatch))
      this.tableForm.wastageBatch = []
      this.$nextTick(() => {
        this.tableForm.wastageBatch = this.combineArr(wastageBatch, _wastageBatch)
      })
    },
    // 数组合并去重
    combineArr (arr1, arr2) {
      return uniqBy(arr1.concat(arr2), 'skuCode')
    },
    onRemove (index) {
      this.tableForm.wastageBatch.splice(index, 1)
    },
    handleSubmit () {
      if (!this.tableForm.wastageBatch.length) {
        this.$message.warning('请添加底版SKU！')
        return
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.handleLoading = true
          // 若有性能问题，后期优化
          const submitData = JSON.parse(JSON.stringify(this.tableForm))
          // 特殊的数据结构 - 字段映射关系
          submitData.wastageBatch = submitData.wastageBatch.map(sku => ({
            availableQuantity: sku.available,
            liableSupplyId: sku.supplyId,
            liableSupplyName: sku.supplyName,
            ...sku
          }))
          OemInventory.wastageCreate(submitData).then(res => {
            if (res.success) {
              this.$message.success('底版报损提交成功！')
              this.$emit('submited')
              this.close()
            }
          }).finally(() => {
            this.handleLoading = false
          })
        }
      })
    },
    close () {
      this.$emit('update:show', false)
    },
    closed () {
      this.$refs.form.resetFields()
      this.tableForm.wastageBatch = []
    }
  }
}
</script>
<style lang="scss">
@import '@assets/scss/_var.scss';
.line-height-20 {
  line-height: 2rem;
}

.logistics-main {
  border: 1px solid #edf0f2;
  .logistics-main--header {
    padding-left: 1rem;
    line-height: 4rem;
    background-color: $color-table-header-bg;
  }

  .logistics-main--content {
    padding: 1rem;
  }
}

.prop-label {
  color: $color-text-primary;
}

.prop-value {
  color: $color-text-minor;
}
.color-primary {
  color: $color-primary;
}
</style>
