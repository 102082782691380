<template>
  <div class="word-break-container">
    <SlListView
      @gotoPage="gotoPage"
      :total="page.total"
      :pageIndex="page.pageIndex"
      :pageSize="page.pageSize"
    >
      <div solt="search">
        <SlSearchForm
          v-model="query"
          :items="searchItems"
          :loading="tableLoading"
          @reset="gotoPage(page.pageSize)"
          @search="gotoPage(page.pageSize)"
        ></SlSearchForm>

        <SlTableToolbar>
          <SlButton type="primary" boxShadow="primary" @click="onOpenDialog('create')">新增</SlButton>
        </SlTableToolbar>

        <SlTableInfo :tableData="tableData" :columns="columns" headerAlign="left">
          <div slot="operation" slot-scope="{row}">
            <el-button class="operation-btn" type="text" @click="onOpenDialog('read', row)">查看</el-button>
          </div>
        </SlTableInfo>
      </div>
    </SlListView>
    <!-- 收货/查看 Dialog -->
    <ApplyDialog :show.sync="visible" @submited="gotoPage" />
    <ApplySingleDialog :show.sync="visibleSingle" :row="row" />
  </div>
</template>

<script>
import CommonUrl from '@api/url.js'
import OemInventory from '@api/oemInventory'
import ApplyDialog from './lossApply/ApplyDialog.vue'
import ApplySingleDialog from './lossApply/ApplySingleDialog.vue'

export default {
  name: 'LossApply',
  components: {
    ApplyDialog,
    ApplySingleDialog
  },
  data () {
    return {
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      query: {},
      searchItems: [
        {
          type: 'input',
          label: '底版SKU',
          name: 'skuCode'
        },
        {
          type: 'single-select',
          label: '状态',
          name: 'auditStatus',
          data: {
            remoteUrl: CommonUrl.wmsDictUrl,
            params: { code: 'AUDIT_STATUS' }
          }
        },
        {
          type: 'date',
          label: '申请时间',
          name: 'createAts',
          data: {
            datetype: 'daterange',
            isBlock: true
          }
        }
      ],
      tableLoading: false,
      tableData: [],
      columns: [
        {
          name: 'skuCode',
          label: '底版SKU'
        }, {
          name: 'skuImage',
          label: '图片',
          isImage: true,
          data: {
            imgSize: '6rem'
          }
        }, {
          name: 'productName',
          label: '商品名称',
          render: (h, { row }) => {
            return (
              <div>
                <p>{row.productName}</p>
                <p class="color-text--minor">{row.attributes}</p>
              </div>
            )
          }
        }, {
          name: 'wastageQuantity',
          label: '损耗数量'
        }, {
          name: 'auditStatusName',
          label: '审核状态'
        }, {
          name: 'handleTypeName',
          label: '处理方式'
        }, {
          name: 'liableSupplyName',
          label: '责任供应商'
        }, {
          name: 'applyTime',
          label: '申请时间'
        }, {
          name: 'auditTime',
          label: '审核时间'
        }, {
          name: 'operation',
          label: '操作'
        }
      ],
      // dialog
      visible: false,
      visibleSingle: false,
      row: {}
    }
  },
  methods: {
    gotoPage (pageSize = 10, pageIndex = 1) {
      const params = this.generateParams(pageSize, pageIndex)
      this.tableLoading = true
      OemInventory.wastagePage(params).then(res => {
        if (res.success) {
          const data = res.data || {}
          this.tableData = data.list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    generateParams (pageSize, pageIndex) {
      let { createAts = [], ...orther } = this.query
      return {
        ...orther,
        pageIndex,
        pageSize,
        applyStartTime: createAts && createAts[0] ? createAts[0] : '',
        applyEndTime: createAts && createAts[1] ? createAts[1] : ''
      }
    },
    onOpenDialog (operationType, row) {
      if (operationType === 'create') {
        this.visible = true
      } else {
        this.row = row
        this.visibleSingle = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.operation-btn {
  margin-left: 0 !important;
  margin-right: 0.8rem;
}
</style>
