<template>
  <SlDialog
    :title="title"
    :visible.sync="show"
    :width="dialogWidth"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :lock-scroll="false"
    :append-to-body="true"
    :before-close="close"
    @cancel="close"
    @closed="closed"
  >
    <SlTableInfo
      :isEmbedTable="true"
      :tableData="tableData"
      :columns="columns"
      headerAlign="left"
      max-height="360"
    ></SlTableInfo>

    <el-form ref="form" :model="tableForm" disabled>
      <el-form-item
        label="损耗原因："
        label-width="110px"
        prop="wastageReason"
        style="margin-top: 16px;"
      >
        <el-input type="textarea" v-model="tableForm.wastageReason" rows="3"></el-input>
      </el-form-item>

      <el-form-item
        v-if="tableForm.auditStatus === 'REJECT'"
        label="驳回原因："
        prop="rejectReason"
        label-width="110px"
        style="margin-top: 16px;"
      >
        <el-input
          type="textarea"
          v-model="tableForm.rejectReason"
          rows="3"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <template #bottom>
      <el-button @click="close">关闭</el-button>
    </template>
  </SlDialog>
</template>
<script>
import OemInventory from '@api/oemInventory'

export default {
  name: 'ApplySingleDialog',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      title: '查看底版报损',
      handleLoading: false,
      dialogWidth: '60%',
      columns: [
        {
          name: 'skuCode',
          label: '底版SKU'
        }, {
          name: 'skuImage',
          label: '图片',
          isImage: true,
          data: {
            imgSize: '6rem'
          }
        }, {
          name: 'productName',
          label: '商品名称'
        }, {
          name: 'availableQuantity',
          label: '可用库存'
        }, {
          name: 'wastageQuantity',
          label: '损耗数量',
          render: (h, data) => {
            let { row = {} } = data
            return <span style="color: #1890ff;">{row.wastageQuantity}</span>
          }
        }
      ],
      tableForm: {
        wastageReason: '',
        rejectReason: ''
      },
      tableData: []
    }
  },
  methods: {
    close () {
      this.$emit('update:show', false)
    },
    closed () {
      this.$refs.form.resetFields()
      this.tableData = []
    }
  },
  watch: {
    show (bool) {
      if (bool) {
        OemInventory.wastageDetail(this.row.id).then(res => {
          if (res.success) {
            this.tableForm = res.data || {}
            this.tableData = (res.data && [res.data]) || []
          }
        })
      }
    }
  }
}
</script>
